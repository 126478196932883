import { derived, get, writable } from 'svelte/store';
import {
	type CustomerAccessTokenCreate$result,
	CustomerAccessTokenCreateStore,
	CustomerAccessTokenDeleteStore,
	CustomerCreateStore,
	CustomerResetPasswordStore,
	CustomerConfirmPasswordResetStore,
	CustomerActivateStore
} from '$houdini';
import { base64Encode } from '$lib/utils/base64';
import { languageStore } from '$lib/store/language.store';
import { ensureCountry, ensureLocale } from '$lib/utils/i18n';

export type CustomerSessionData = NonNullable<
	CustomerAccessTokenCreate$result['customerAccessTokenCreate']
>['customerAccessToken'];

const createCustomerSessionStore = () => {
	const readFromStorage = () => {
		const storedToken = localStorage.getItem('customerAccessToken');
		if (storedToken) {
			return JSON.parse(storedToken) as CustomerSessionData;
		}
		return undefined;
	};
	const getLanguageData = () => ({
		language: ensureLocale(get(languageStore).locale),
		country: ensureCountry(get(languageStore).country)
	});

	const token = writable<CustomerSessionData | undefined>(undefined);

	const writeToStorage = (data: CustomerSessionData | undefined) => {
		if (data) {
			localStorage.setItem('customerAccessToken', JSON.stringify(data));
		} else {
			localStorage.removeItem('customerAccessToken');
		}
	};

	const login = async (email: string, password: string) => {
		const response = await new CustomerAccessTokenCreateStore().mutate({
			input: {
				email,
				password
			},
			...getLanguageData()
		});

		if (response.data?.customerAccessTokenCreate?.customerUserErrors?.length) {
			throw response.data?.customerAccessTokenCreate?.customerUserErrors;
		}
		if (response.data?.customerAccessTokenCreate?.customerAccessToken) {
			token.set(response.data.customerAccessTokenCreate.customerAccessToken);
			writeToStorage(response.data.customerAccessTokenCreate.customerAccessToken);
		}
	};

	const register = async (email: string, password: string) => {
		const response = await new CustomerCreateStore().mutate({
			input: {
				email,
				password
			},
			...getLanguageData()
		});

		if (response.data?.customerCreate?.customer?.id) {
			await fetch(
				`/api/customer/create/${base64Encode(response.data.customerCreate.customer.id)}`,
				{
					body: JSON.stringify({ locale: get(languageStore).locale }),
					method: 'POST'
				}
			);
		}

		if (response.data?.customerCreate?.customerUserErrors?.length) {
			throw response.data?.customerCreate?.customerUserErrors;
		}
		await login(email, password);
	};

	const requestPasswordReset = async (email: string) => {
		const response = await new CustomerResetPasswordStore().mutate({
			email,
			...getLanguageData()
		});

		if (response.data?.customerRecover?.customerUserErrors?.length) {
			throw response.data?.customerRecover?.customerUserErrors;
		}
	};

	const confirmPasswordReset = async (resetToken: URL, password: string) => {
		const response = await new CustomerConfirmPasswordResetStore().mutate({
			token: resetToken,
			password,
			...getLanguageData()
		});

		if (response.data?.customerResetByUrl?.customerUserErrors?.length) {
			throw response.data?.customerResetByUrl?.customerUserErrors;
		}

		if (response.data?.customerResetByUrl?.customerAccessToken) {
			token.set(response.data.customerResetByUrl.customerAccessToken);
			writeToStorage(response.data.customerResetByUrl.customerAccessToken);
		}
	};

	const activateCustomer = async (activationToken: URL, password: string) => {
		const response = await new CustomerActivateStore().mutate({
			token: activationToken,
			password,
			...getLanguageData()
		});

		if (response.data?.customerActivateByUrl?.customerUserErrors?.length) {
			throw response.data?.customerActivateByUrl?.customerUserErrors;
		}

		if (response.data?.customerActivateByUrl?.customerAccessToken) {
			token.set(response.data.customerActivateByUrl.customerAccessToken);
			writeToStorage(response.data.customerActivateByUrl.customerAccessToken);
		}
	};

	const logout = async () => {
		const tokenData = get(token);
		if (tokenData) {
			await new CustomerAccessTokenDeleteStore().mutate({
				customerAccessTokenDeleteInput: tokenData.accessToken
			});
		}

		token.set(undefined);
		localStorage.removeItem('customerAccessToken');
	};

	const init = () => {
		const stored = readFromStorage();
		if (stored) {
			token.set(stored);
		}
	};

	return {
		...derived(token, ($token) => ({
			data: $token,
			isLoggedIn: !!$token?.accessToken
		})),
		login,
		logout,
		register,
		requestPasswordReset,
		confirmPasswordReset,
		activateCustomer,
		init
	};
};

export const customerSessionStore = createCustomerSessionStore();
