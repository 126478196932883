import artifact from '$houdini/artifacts/CustomerAccessTokenDelete'
import { MutationStore } from '../runtime/stores/mutation'

export class CustomerAccessTokenDeleteStore extends MutationStore {
	constructor() {
		super({
			artifact,
		})
	}
}
