export const ArticleSortKeys = {
    "AUTHOR": "AUTHOR",
    "BLOG_TITLE": "BLOG_TITLE",
    "ID": "ID",
    "PUBLISHED_AT": "PUBLISHED_AT",
    "RELEVANCE": "RELEVANCE",
    "TITLE": "TITLE",
    "UPDATED_AT": "UPDATED_AT"
};

export const BlogSortKeys = {
    "HANDLE": "HANDLE",
    "ID": "ID",
    "RELEVANCE": "RELEVANCE",
    "TITLE": "TITLE"
};

export const CardBrand = {
    "AMERICAN_EXPRESS": "AMERICAN_EXPRESS",
    "DINERS_CLUB": "DINERS_CLUB",
    "DISCOVER": "DISCOVER",
    "JCB": "JCB",
    "MASTERCARD": "MASTERCARD",
    "VISA": "VISA"
};

export const CartCardSource = {
    "SAVED_CREDIT_CARD": "SAVED_CREDIT_CARD"
};

export const CartErrorCode = {
    "INVALID": "INVALID",
    "INVALID_DELIVERY_GROUP": "INVALID_DELIVERY_GROUP",
    "INVALID_DELIVERY_OPTION": "INVALID_DELIVERY_OPTION",
    "INVALID_MERCHANDISE_LINE": "INVALID_MERCHANDISE_LINE",
    "INVALID_METAFIELDS": "INVALID_METAFIELDS",
    "INVALID_PAYMENT": "INVALID_PAYMENT",
    "INVALID_PAYMENT_EMPTY_CART": "INVALID_PAYMENT_EMPTY_CART",
    "LESS_THAN": "LESS_THAN",
    "MISSING_DISCOUNT_CODE": "MISSING_DISCOUNT_CODE",
    "MISSING_NOTE": "MISSING_NOTE",
    "PAYMENT_METHOD_NOT_SUPPORTED": "PAYMENT_METHOD_NOT_SUPPORTED",
    "VALIDATION_CUSTOM": "VALIDATION_CUSTOM"
};

export const CheckoutErrorCode = {
    "ALREADY_COMPLETED": "ALREADY_COMPLETED",
    "BAD_DOMAIN": "BAD_DOMAIN",
    "BLANK": "BLANK",
    "CART_DOES_NOT_MEET_DISCOUNT_REQUIREMENTS_NOTICE": "CART_DOES_NOT_MEET_DISCOUNT_REQUIREMENTS_NOTICE",
    "CUSTOMER_ALREADY_USED_ONCE_PER_CUSTOMER_DISCOUNT_NOTICE": "CUSTOMER_ALREADY_USED_ONCE_PER_CUSTOMER_DISCOUNT_NOTICE",
    "DISCOUNT_ALREADY_APPLIED": "DISCOUNT_ALREADY_APPLIED",
    "DISCOUNT_CODE_APPLICATION_FAILED": "DISCOUNT_CODE_APPLICATION_FAILED",
    "DISCOUNT_DISABLED": "DISCOUNT_DISABLED",
    "DISCOUNT_EXPIRED": "DISCOUNT_EXPIRED",
    "DISCOUNT_LIMIT_REACHED": "DISCOUNT_LIMIT_REACHED",
    "DISCOUNT_NOT_FOUND": "DISCOUNT_NOT_FOUND",
    "EMPTY": "EMPTY",
    "EXPIRED_QUEUE_TOKEN": "EXPIRED_QUEUE_TOKEN",
    "GIFT_CARD_ALREADY_APPLIED": "GIFT_CARD_ALREADY_APPLIED",
    "GIFT_CARD_CODE_INVALID": "GIFT_CARD_CODE_INVALID",
    "GIFT_CARD_CURRENCY_MISMATCH": "GIFT_CARD_CURRENCY_MISMATCH",
    "GIFT_CARD_DEPLETED": "GIFT_CARD_DEPLETED",
    "GIFT_CARD_DISABLED": "GIFT_CARD_DISABLED",
    "GIFT_CARD_EXPIRED": "GIFT_CARD_EXPIRED",
    "GIFT_CARD_NOT_FOUND": "GIFT_CARD_NOT_FOUND",
    "GIFT_CARD_UNUSABLE": "GIFT_CARD_UNUSABLE",
    "GREATER_THAN_OR_EQUAL_TO": "GREATER_THAN_OR_EQUAL_TO",
    "HIGHER_VALUE_DISCOUNT_APPLIED": "HIGHER_VALUE_DISCOUNT_APPLIED",
    "INVALID": "INVALID",
    "INVALID_COUNTRY_AND_CURRENCY": "INVALID_COUNTRY_AND_CURRENCY",
    "INVALID_FOR_COUNTRY": "INVALID_FOR_COUNTRY",
    "INVALID_FOR_COUNTRY_AND_PROVINCE": "INVALID_FOR_COUNTRY_AND_PROVINCE",
    "INVALID_PROVINCE_IN_COUNTRY": "INVALID_PROVINCE_IN_COUNTRY",
    "INVALID_QUEUE_TOKEN": "INVALID_QUEUE_TOKEN",
    "INVALID_REGION_IN_COUNTRY": "INVALID_REGION_IN_COUNTRY",
    "INVALID_STATE_IN_COUNTRY": "INVALID_STATE_IN_COUNTRY",
    "LESS_THAN": "LESS_THAN",
    "LESS_THAN_OR_EQUAL_TO": "LESS_THAN_OR_EQUAL_TO",
    "LINE_ITEM_NOT_FOUND": "LINE_ITEM_NOT_FOUND",
    "LOCKED": "LOCKED",
    "MAXIMUM_DISCOUNT_CODE_LIMIT_REACHED": "MAXIMUM_DISCOUNT_CODE_LIMIT_REACHED",
    "MISSING_PAYMENT_INPUT": "MISSING_PAYMENT_INPUT",
    "NOT_ENOUGH_IN_STOCK": "NOT_ENOUGH_IN_STOCK",
    "NOT_SUPPORTED": "NOT_SUPPORTED",
    "PRESENT": "PRESENT",
    "PRODUCT_NOT_AVAILABLE": "PRODUCT_NOT_AVAILABLE",
    "SHIPPING_RATE_EXPIRED": "SHIPPING_RATE_EXPIRED",
    "THROTTLED_DURING_CHECKOUT": "THROTTLED_DURING_CHECKOUT",
    "TOO_LONG": "TOO_LONG",
    "TOTAL_PRICE_MISMATCH": "TOTAL_PRICE_MISMATCH",
    "UNABLE_TO_APPLY": "UNABLE_TO_APPLY"
};

export const CollectionSortKeys = {
    "ID": "ID",
    "RELEVANCE": "RELEVANCE",
    "TITLE": "TITLE",
    "UPDATED_AT": "UPDATED_AT"
};

export const CompletionErrorCode = {
    "ERROR": "ERROR",
    "INVENTORY_RESERVATION_ERROR": "INVENTORY_RESERVATION_ERROR",
    "PAYMENT_AMOUNT_TOO_SMALL": "PAYMENT_AMOUNT_TOO_SMALL",
    "PAYMENT_CALL_ISSUER": "PAYMENT_CALL_ISSUER",
    "PAYMENT_CARD_DECLINED": "PAYMENT_CARD_DECLINED",
    "PAYMENT_ERROR": "PAYMENT_ERROR",
    "PAYMENT_GATEWAY_NOT_ENABLED_ERROR": "PAYMENT_GATEWAY_NOT_ENABLED_ERROR",
    "PAYMENT_INSUFFICIENT_FUNDS": "PAYMENT_INSUFFICIENT_FUNDS",
    "PAYMENT_INVALID_BILLING_ADDRESS": "PAYMENT_INVALID_BILLING_ADDRESS",
    "PAYMENT_INVALID_CREDIT_CARD": "PAYMENT_INVALID_CREDIT_CARD",
    "PAYMENT_INVALID_CURRENCY": "PAYMENT_INVALID_CURRENCY",
    "PAYMENT_INVALID_PAYMENT_METHOD": "PAYMENT_INVALID_PAYMENT_METHOD",
    "PAYMENT_TRANSIENT_ERROR": "PAYMENT_TRANSIENT_ERROR"
};

export const CountryCode = {
    "AC": "AC",
    "AD": "AD",
    "AE": "AE",
    "AF": "AF",
    "AG": "AG",
    "AI": "AI",
    "AL": "AL",
    "AM": "AM",
    "AN": "AN",
    "AO": "AO",
    "AR": "AR",
    "AT": "AT",
    "AU": "AU",
    "AW": "AW",
    "AX": "AX",
    "AZ": "AZ",
    "BA": "BA",
    "BB": "BB",
    "BD": "BD",
    "BE": "BE",
    "BF": "BF",
    "BG": "BG",
    "BH": "BH",
    "BI": "BI",
    "BJ": "BJ",
    "BL": "BL",
    "BM": "BM",
    "BN": "BN",
    "BO": "BO",
    "BQ": "BQ",
    "BR": "BR",
    "BS": "BS",
    "BT": "BT",
    "BV": "BV",
    "BW": "BW",
    "BY": "BY",
    "BZ": "BZ",
    "CA": "CA",
    "CC": "CC",
    "CD": "CD",
    "CF": "CF",
    "CG": "CG",
    "CH": "CH",
    "CI": "CI",
    "CK": "CK",
    "CL": "CL",
    "CM": "CM",
    "CN": "CN",
    "CO": "CO",
    "CR": "CR",
    "CU": "CU",
    "CV": "CV",
    "CW": "CW",
    "CX": "CX",
    "CY": "CY",
    "CZ": "CZ",
    "DE": "DE",
    "DJ": "DJ",
    "DK": "DK",
    "DM": "DM",
    "DO": "DO",
    "DZ": "DZ",
    "EC": "EC",
    "EE": "EE",
    "EG": "EG",
    "EH": "EH",
    "ER": "ER",
    "ES": "ES",
    "ET": "ET",
    "FI": "FI",
    "FJ": "FJ",
    "FK": "FK",
    "FO": "FO",
    "FR": "FR",
    "GA": "GA",
    "GB": "GB",
    "GD": "GD",
    "GE": "GE",
    "GF": "GF",
    "GG": "GG",
    "GH": "GH",
    "GI": "GI",
    "GL": "GL",
    "GM": "GM",
    "GN": "GN",
    "GP": "GP",
    "GQ": "GQ",
    "GR": "GR",
    "GS": "GS",
    "GT": "GT",
    "GW": "GW",
    "GY": "GY",
    "HK": "HK",
    "HM": "HM",
    "HN": "HN",
    "HR": "HR",
    "HT": "HT",
    "HU": "HU",
    "ID": "ID",
    "IE": "IE",
    "IL": "IL",
    "IM": "IM",
    "IN": "IN",
    "IO": "IO",
    "IQ": "IQ",
    "IR": "IR",
    "IS": "IS",
    "IT": "IT",
    "JE": "JE",
    "JM": "JM",
    "JO": "JO",
    "JP": "JP",
    "KE": "KE",
    "KG": "KG",
    "KH": "KH",
    "KI": "KI",
    "KM": "KM",
    "KN": "KN",
    "KP": "KP",
    "KR": "KR",
    "KW": "KW",
    "KY": "KY",
    "KZ": "KZ",
    "LA": "LA",
    "LB": "LB",
    "LC": "LC",
    "LI": "LI",
    "LK": "LK",
    "LR": "LR",
    "LS": "LS",
    "LT": "LT",
    "LU": "LU",
    "LV": "LV",
    "LY": "LY",
    "MA": "MA",
    "MC": "MC",
    "MD": "MD",
    "ME": "ME",
    "MF": "MF",
    "MG": "MG",
    "MK": "MK",
    "ML": "ML",
    "MM": "MM",
    "MN": "MN",
    "MO": "MO",
    "MQ": "MQ",
    "MR": "MR",
    "MS": "MS",
    "MT": "MT",
    "MU": "MU",
    "MV": "MV",
    "MW": "MW",
    "MX": "MX",
    "MY": "MY",
    "MZ": "MZ",
    "NA": "NA",
    "NC": "NC",
    "NE": "NE",
    "NF": "NF",
    "NG": "NG",
    "NI": "NI",
    "NL": "NL",
    "NO": "NO",
    "NP": "NP",
    "NR": "NR",
    "NU": "NU",
    "NZ": "NZ",
    "OM": "OM",
    "PA": "PA",
    "PE": "PE",
    "PF": "PF",
    "PG": "PG",
    "PH": "PH",
    "PK": "PK",
    "PL": "PL",
    "PM": "PM",
    "PN": "PN",
    "PS": "PS",
    "PT": "PT",
    "PY": "PY",
    "QA": "QA",
    "RE": "RE",
    "RO": "RO",
    "RS": "RS",
    "RU": "RU",
    "RW": "RW",
    "SA": "SA",
    "SB": "SB",
    "SC": "SC",
    "SD": "SD",
    "SE": "SE",
    "SG": "SG",
    "SH": "SH",
    "SI": "SI",
    "SJ": "SJ",
    "SK": "SK",
    "SL": "SL",
    "SM": "SM",
    "SN": "SN",
    "SO": "SO",
    "SR": "SR",
    "SS": "SS",
    "ST": "ST",
    "SV": "SV",
    "SX": "SX",
    "SY": "SY",
    "SZ": "SZ",
    "TA": "TA",
    "TC": "TC",
    "TD": "TD",
    "TF": "TF",
    "TG": "TG",
    "TH": "TH",
    "TJ": "TJ",
    "TK": "TK",
    "TL": "TL",
    "TM": "TM",
    "TN": "TN",
    "TO": "TO",
    "TR": "TR",
    "TT": "TT",
    "TV": "TV",
    "TW": "TW",
    "TZ": "TZ",
    "UA": "UA",
    "UG": "UG",
    "UM": "UM",
    "US": "US",
    "UY": "UY",
    "UZ": "UZ",
    "VA": "VA",
    "VC": "VC",
    "VE": "VE",
    "VG": "VG",
    "VN": "VN",
    "VU": "VU",
    "WF": "WF",
    "WS": "WS",
    "XK": "XK",
    "YE": "YE",
    "YT": "YT",
    "ZA": "ZA",
    "ZM": "ZM",
    "ZW": "ZW",
    "ZZ": "ZZ"
};

export const CropRegion = {
    "BOTTOM": "BOTTOM",
    "CENTER": "CENTER",
    "LEFT": "LEFT",
    "RIGHT": "RIGHT",
    "TOP": "TOP"
};

export const CurrencyCode = {
    "AED": "AED",
    "AFN": "AFN",
    "ALL": "ALL",
    "AMD": "AMD",
    "ANG": "ANG",
    "AOA": "AOA",
    "ARS": "ARS",
    "AUD": "AUD",
    "AWG": "AWG",
    "AZN": "AZN",
    "BAM": "BAM",
    "BBD": "BBD",
    "BDT": "BDT",
    "BGN": "BGN",
    "BHD": "BHD",
    "BIF": "BIF",
    "BMD": "BMD",
    "BND": "BND",
    "BOB": "BOB",
    "BRL": "BRL",
    "BSD": "BSD",
    "BTN": "BTN",
    "BWP": "BWP",
    "BYN": "BYN",
    "BYR": "BYR",
    "BZD": "BZD",
    "CAD": "CAD",
    "CDF": "CDF",
    "CHF": "CHF",
    "CLP": "CLP",
    "CNY": "CNY",
    "COP": "COP",
    "CRC": "CRC",
    "CVE": "CVE",
    "CZK": "CZK",
    "DJF": "DJF",
    "DKK": "DKK",
    "DOP": "DOP",
    "DZD": "DZD",
    "EGP": "EGP",
    "ERN": "ERN",
    "ETB": "ETB",
    "EUR": "EUR",
    "FJD": "FJD",
    "FKP": "FKP",
    "GBP": "GBP",
    "GEL": "GEL",
    "GHS": "GHS",
    "GIP": "GIP",
    "GMD": "GMD",
    "GNF": "GNF",
    "GTQ": "GTQ",
    "GYD": "GYD",
    "HKD": "HKD",
    "HNL": "HNL",
    "HRK": "HRK",
    "HTG": "HTG",
    "HUF": "HUF",
    "IDR": "IDR",
    "ILS": "ILS",
    "INR": "INR",
    "IQD": "IQD",
    "IRR": "IRR",
    "ISK": "ISK",
    "JEP": "JEP",
    "JMD": "JMD",
    "JOD": "JOD",
    "JPY": "JPY",
    "KES": "KES",
    "KGS": "KGS",
    "KHR": "KHR",
    "KID": "KID",
    "KMF": "KMF",
    "KRW": "KRW",
    "KWD": "KWD",
    "KYD": "KYD",
    "KZT": "KZT",
    "LAK": "LAK",
    "LBP": "LBP",
    "LKR": "LKR",
    "LRD": "LRD",
    "LSL": "LSL",
    "LTL": "LTL",
    "LVL": "LVL",
    "LYD": "LYD",
    "MAD": "MAD",
    "MDL": "MDL",
    "MGA": "MGA",
    "MKD": "MKD",
    "MMK": "MMK",
    "MNT": "MNT",
    "MOP": "MOP",
    "MRU": "MRU",
    "MUR": "MUR",
    "MVR": "MVR",
    "MWK": "MWK",
    "MXN": "MXN",
    "MYR": "MYR",
    "MZN": "MZN",
    "NAD": "NAD",
    "NGN": "NGN",
    "NIO": "NIO",
    "NOK": "NOK",
    "NPR": "NPR",
    "NZD": "NZD",
    "OMR": "OMR",
    "PAB": "PAB",
    "PEN": "PEN",
    "PGK": "PGK",
    "PHP": "PHP",
    "PKR": "PKR",
    "PLN": "PLN",
    "PYG": "PYG",
    "QAR": "QAR",
    "RON": "RON",
    "RSD": "RSD",
    "RUB": "RUB",
    "RWF": "RWF",
    "SAR": "SAR",
    "SBD": "SBD",
    "SCR": "SCR",
    "SDG": "SDG",
    "SEK": "SEK",
    "SGD": "SGD",
    "SHP": "SHP",
    "SLL": "SLL",
    "SOS": "SOS",
    "SRD": "SRD",
    "SSP": "SSP",
    "STD": "STD",
    "STN": "STN",
    "SYP": "SYP",
    "SZL": "SZL",
    "THB": "THB",
    "TJS": "TJS",
    "TMT": "TMT",
    "TND": "TND",
    "TOP": "TOP",
    "TRY": "TRY",
    "TTD": "TTD",
    "TWD": "TWD",
    "TZS": "TZS",
    "UAH": "UAH",
    "UGX": "UGX",
    "USD": "USD",
    "UYU": "UYU",
    "UZS": "UZS",
    "VED": "VED",
    "VEF": "VEF",
    "VES": "VES",
    "VND": "VND",
    "VUV": "VUV",
    "WST": "WST",
    "XAF": "XAF",
    "XCD": "XCD",
    "XOF": "XOF",
    "XPF": "XPF",
    "XXX": "XXX",
    "YER": "YER",
    "ZAR": "ZAR",
    "ZMW": "ZMW"
};

export const CustomerErrorCode = {
    "ALREADY_ENABLED": "ALREADY_ENABLED",
    "BAD_DOMAIN": "BAD_DOMAIN",
    "BLANK": "BLANK",
    "CONTAINS_HTML_TAGS": "CONTAINS_HTML_TAGS",
    "CONTAINS_URL": "CONTAINS_URL",
    "CUSTOMER_DISABLED": "CUSTOMER_DISABLED",
    "INVALID": "INVALID",
    "INVALID_MULTIPASS_REQUEST": "INVALID_MULTIPASS_REQUEST",
    "NOT_FOUND": "NOT_FOUND",
    "PASSWORD_STARTS_OR_ENDS_WITH_WHITESPACE": "PASSWORD_STARTS_OR_ENDS_WITH_WHITESPACE",
    "TAKEN": "TAKEN",
    "TOKEN_INVALID": "TOKEN_INVALID",
    "TOO_LONG": "TOO_LONG",
    "TOO_SHORT": "TOO_SHORT",
    "UNIDENTIFIED_CUSTOMER": "UNIDENTIFIED_CUSTOMER"
};

export const DeliveryMethodType = {
    "LOCAL": "LOCAL",
    "NONE": "NONE",
    "PICKUP_POINT": "PICKUP_POINT",
    "PICK_UP": "PICK_UP",
    "RETAIL": "RETAIL",
    "SHIPPING": "SHIPPING"
};

export const DigitalWallet = {
    "ANDROID_PAY": "ANDROID_PAY",
    "APPLE_PAY": "APPLE_PAY",
    "GOOGLE_PAY": "GOOGLE_PAY",
    "SHOPIFY_PAY": "SHOPIFY_PAY"
};

export const DiscountApplicationAllocationMethod = {
    "ACROSS": "ACROSS",
    "EACH": "EACH",
    "ONE": "ONE"
};

export const DiscountApplicationTargetSelection = {
    "ALL": "ALL",
    "ENTITLED": "ENTITLED",
    "EXPLICIT": "EXPLICIT"
};

export const DiscountApplicationTargetType = {
    "LINE_ITEM": "LINE_ITEM",
    "SHIPPING_LINE": "SHIPPING_LINE"
};

export const FilterType = {
    "BOOLEAN": "BOOLEAN",
    "LIST": "LIST",
    "PRICE_RANGE": "PRICE_RANGE"
};

export const ImageContentType = {
    "JPG": "JPG",
    "PNG": "PNG",
    "WEBP": "WEBP"
};

export const LanguageCode = {
    "AF": "AF",
    "AK": "AK",
    "AM": "AM",
    "AR": "AR",
    "AS": "AS",
    "AZ": "AZ",
    "BE": "BE",
    "BG": "BG",
    "BM": "BM",
    "BN": "BN",
    "BO": "BO",
    "BR": "BR",
    "BS": "BS",
    "CA": "CA",
    "CE": "CE",
    "CKB": "CKB",
    "CS": "CS",
    "CU": "CU",
    "CY": "CY",
    "DA": "DA",
    "DE": "DE",
    "DZ": "DZ",
    "EE": "EE",
    "EL": "EL",
    "EN": "EN",
    "EO": "EO",
    "ES": "ES",
    "ET": "ET",
    "EU": "EU",
    "FA": "FA",
    "FF": "FF",
    "FI": "FI",
    "FIL": "FIL",
    "FO": "FO",
    "FR": "FR",
    "FY": "FY",
    "GA": "GA",
    "GD": "GD",
    "GL": "GL",
    "GU": "GU",
    "GV": "GV",
    "HA": "HA",
    "HE": "HE",
    "HI": "HI",
    "HR": "HR",
    "HU": "HU",
    "HY": "HY",
    "IA": "IA",
    "ID": "ID",
    "IG": "IG",
    "II": "II",
    "IS": "IS",
    "IT": "IT",
    "JA": "JA",
    "JV": "JV",
    "KA": "KA",
    "KI": "KI",
    "KK": "KK",
    "KL": "KL",
    "KM": "KM",
    "KN": "KN",
    "KO": "KO",
    "KS": "KS",
    "KU": "KU",
    "KW": "KW",
    "KY": "KY",
    "LA": "LA",
    "LB": "LB",
    "LG": "LG",
    "LN": "LN",
    "LO": "LO",
    "LT": "LT",
    "LU": "LU",
    "LV": "LV",
    "MG": "MG",
    "MI": "MI",
    "MK": "MK",
    "ML": "ML",
    "MN": "MN",
    "MO": "MO",
    "MR": "MR",
    "MS": "MS",
    "MT": "MT",
    "MY": "MY",
    "NB": "NB",
    "ND": "ND",
    "NE": "NE",
    "NL": "NL",
    "NN": "NN",
    "NO": "NO",
    "OM": "OM",
    "OR": "OR",
    "OS": "OS",
    "PA": "PA",
    "PL": "PL",
    "PS": "PS",
    "PT": "PT",
    "PT_BR": "PT_BR",
    "PT_PT": "PT_PT",
    "QU": "QU",
    "RM": "RM",
    "RN": "RN",
    "RO": "RO",
    "RU": "RU",
    "RW": "RW",
    "SA": "SA",
    "SC": "SC",
    "SD": "SD",
    "SE": "SE",
    "SG": "SG",
    "SH": "SH",
    "SI": "SI",
    "SK": "SK",
    "SL": "SL",
    "SN": "SN",
    "SO": "SO",
    "SQ": "SQ",
    "SR": "SR",
    "SU": "SU",
    "SV": "SV",
    "SW": "SW",
    "TA": "TA",
    "TE": "TE",
    "TG": "TG",
    "TH": "TH",
    "TI": "TI",
    "TK": "TK",
    "TO": "TO",
    "TR": "TR",
    "TT": "TT",
    "UG": "UG",
    "UK": "UK",
    "UR": "UR",
    "UZ": "UZ",
    "VI": "VI",
    "VO": "VO",
    "WO": "WO",
    "XH": "XH",
    "YI": "YI",
    "YO": "YO",
    "ZH": "ZH",
    "ZH_CN": "ZH_CN",
    "ZH_TW": "ZH_TW",
    "ZU": "ZU"
};

export const LocationSortKeys = {
    "CITY": "CITY",
    "DISTANCE": "DISTANCE",
    "ID": "ID",
    "NAME": "NAME"
};

export const MediaContentType = {
    "EXTERNAL_VIDEO": "EXTERNAL_VIDEO",
    "IMAGE": "IMAGE",
    "MODEL_3D": "MODEL_3D",
    "VIDEO": "VIDEO"
};

export const MediaHost = {
    "VIMEO": "VIMEO",
    "YOUTUBE": "YOUTUBE"
};

export const MediaPresentationFormat = {
    "IMAGE": "IMAGE",
    "MODEL_VIEWER": "MODEL_VIEWER"
};

export const MenuItemType = {
    "ARTICLE": "ARTICLE",
    "BLOG": "BLOG",
    "CATALOG": "CATALOG",
    "COLLECTION": "COLLECTION",
    "COLLECTIONS": "COLLECTIONS",
    "FRONTPAGE": "FRONTPAGE",
    "HTTP": "HTTP",
    "METAOBJECT": "METAOBJECT",
    "PAGE": "PAGE",
    "PRODUCT": "PRODUCT",
    "SEARCH": "SEARCH",
    "SHOP_POLICY": "SHOP_POLICY"
};

export const MetafieldDeleteErrorCode = {
    "INVALID_OWNER": "INVALID_OWNER",
    "METAFIELD_DOES_NOT_EXIST": "METAFIELD_DOES_NOT_EXIST"
};

export const MetafieldsSetUserErrorCode = {
    "BLANK": "BLANK",
    "INCLUSION": "INCLUSION",
    "INVALID_OWNER": "INVALID_OWNER",
    "INVALID_TYPE": "INVALID_TYPE",
    "INVALID_VALUE": "INVALID_VALUE",
    "LESS_THAN_OR_EQUAL_TO": "LESS_THAN_OR_EQUAL_TO",
    "PRESENT": "PRESENT",
    "TOO_LONG": "TOO_LONG",
    "TOO_SHORT": "TOO_SHORT"
};

export const OrderCancelReason = {
    "CUSTOMER": "CUSTOMER",
    "DECLINED": "DECLINED",
    "FRAUD": "FRAUD",
    "INVENTORY": "INVENTORY",
    "OTHER": "OTHER",
    "STAFF": "STAFF"
};

export const OrderFinancialStatus = {
    "AUTHORIZED": "AUTHORIZED",
    "PAID": "PAID",
    "PARTIALLY_PAID": "PARTIALLY_PAID",
    "PARTIALLY_REFUNDED": "PARTIALLY_REFUNDED",
    "PENDING": "PENDING",
    "REFUNDED": "REFUNDED",
    "VOIDED": "VOIDED"
};

export const OrderFulfillmentStatus = {
    "FULFILLED": "FULFILLED",
    "IN_PROGRESS": "IN_PROGRESS",
    "ON_HOLD": "ON_HOLD",
    "OPEN": "OPEN",
    "PARTIALLY_FULFILLED": "PARTIALLY_FULFILLED",
    "PENDING_FULFILLMENT": "PENDING_FULFILLMENT",
    "RESTOCKED": "RESTOCKED",
    "SCHEDULED": "SCHEDULED",
    "UNFULFILLED": "UNFULFILLED"
};

export const OrderSortKeys = {
    "ID": "ID",
    "PROCESSED_AT": "PROCESSED_AT",
    "RELEVANCE": "RELEVANCE",
    "TOTAL_PRICE": "TOTAL_PRICE"
};

export const PageSortKeys = {
    "ID": "ID",
    "RELEVANCE": "RELEVANCE",
    "TITLE": "TITLE",
    "UPDATED_AT": "UPDATED_AT"
};

export const PaymentTokenType = {
    "APPLE_PAY": "APPLE_PAY",
    "GOOGLE_PAY": "GOOGLE_PAY",
    "SHOPIFY_PAY": "SHOPIFY_PAY",
    "STRIPE_VAULT_TOKEN": "STRIPE_VAULT_TOKEN",
    "VAULT": "VAULT"
};

export const PredictiveSearchLimitScope = {
    "ALL": "ALL",
    "EACH": "EACH"
};

export const PredictiveSearchType = {
    "ARTICLE": "ARTICLE",
    "COLLECTION": "COLLECTION",
    "PAGE": "PAGE",
    "PRODUCT": "PRODUCT",
    "QUERY": "QUERY"
};

export const ProductCollectionSortKeys = {
    "BEST_SELLING": "BEST_SELLING",
    "COLLECTION_DEFAULT": "COLLECTION_DEFAULT",
    "CREATED": "CREATED",
    "ID": "ID",
    "MANUAL": "MANUAL",
    "PRICE": "PRICE",
    "RELEVANCE": "RELEVANCE",
    "TITLE": "TITLE"
};

export const ProductImageSortKeys = {
    "CREATED_AT": "CREATED_AT",
    "ID": "ID",
    "POSITION": "POSITION",
    "RELEVANCE": "RELEVANCE"
};

export const ProductMediaSortKeys = {
    "ID": "ID",
    "POSITION": "POSITION",
    "RELEVANCE": "RELEVANCE"
};

export const ProductRecommendationIntent = {
    "COMPLEMENTARY": "COMPLEMENTARY",
    "RELATED": "RELATED"
};

export const ProductSortKeys = {
    "BEST_SELLING": "BEST_SELLING",
    "CREATED_AT": "CREATED_AT",
    "ID": "ID",
    "PRICE": "PRICE",
    "PRODUCT_TYPE": "PRODUCT_TYPE",
    "RELEVANCE": "RELEVANCE",
    "TITLE": "TITLE",
    "UPDATED_AT": "UPDATED_AT",
    "VENDOR": "VENDOR"
};

export const ProductVariantSortKeys = {
    "ID": "ID",
    "POSITION": "POSITION",
    "RELEVANCE": "RELEVANCE",
    "SKU": "SKU",
    "TITLE": "TITLE"
};

export const SearchPrefixQueryType = {
    "LAST": "LAST",
    "NONE": "NONE"
};

export const SearchSortKeys = {
    "PRICE": "PRICE",
    "RELEVANCE": "RELEVANCE"
};

export const SearchType = {
    "ARTICLE": "ARTICLE",
    "PAGE": "PAGE",
    "PRODUCT": "PRODUCT"
};

export const SearchUnavailableProductsType = {
    "HIDE": "HIDE",
    "LAST": "LAST",
    "SHOW": "SHOW"
};

export const SearchableField = {
    "AUTHOR": "AUTHOR",
    "BODY": "BODY",
    "PRODUCT_TYPE": "PRODUCT_TYPE",
    "TAG": "TAG",
    "TITLE": "TITLE",
    "VARIANTS_BARCODE": "VARIANTS_BARCODE",
    "VARIANTS_SKU": "VARIANTS_SKU",
    "VARIANTS_TITLE": "VARIANTS_TITLE",
    "VENDOR": "VENDOR"
};

export const SellingPlanCheckoutChargeType = {
    "PERCENTAGE": "PERCENTAGE",
    "PRICE": "PRICE"
};

export const SubmissionErrorCode = {
    "BUYER_IDENTITY_EMAIL_IS_INVALID": "BUYER_IDENTITY_EMAIL_IS_INVALID",
    "BUYER_IDENTITY_EMAIL_REQUIRED": "BUYER_IDENTITY_EMAIL_REQUIRED",
    "BUYER_IDENTITY_PHONE_IS_INVALID": "BUYER_IDENTITY_PHONE_IS_INVALID",
    "DELIVERY_ADDRESS1_INVALID": "DELIVERY_ADDRESS1_INVALID",
    "DELIVERY_ADDRESS1_REQUIRED": "DELIVERY_ADDRESS1_REQUIRED",
    "DELIVERY_ADDRESS1_TOO_LONG": "DELIVERY_ADDRESS1_TOO_LONG",
    "DELIVERY_ADDRESS2_INVALID": "DELIVERY_ADDRESS2_INVALID",
    "DELIVERY_ADDRESS2_REQUIRED": "DELIVERY_ADDRESS2_REQUIRED",
    "DELIVERY_ADDRESS2_TOO_LONG": "DELIVERY_ADDRESS2_TOO_LONG",
    "DELIVERY_ADDRESS_REQUIRED": "DELIVERY_ADDRESS_REQUIRED",
    "DELIVERY_CITY_INVALID": "DELIVERY_CITY_INVALID",
    "DELIVERY_CITY_REQUIRED": "DELIVERY_CITY_REQUIRED",
    "DELIVERY_CITY_TOO_LONG": "DELIVERY_CITY_TOO_LONG",
    "DELIVERY_COMPANY_INVALID": "DELIVERY_COMPANY_INVALID",
    "DELIVERY_COMPANY_REQUIRED": "DELIVERY_COMPANY_REQUIRED",
    "DELIVERY_COMPANY_TOO_LONG": "DELIVERY_COMPANY_TOO_LONG",
    "DELIVERY_COUNTRY_REQUIRED": "DELIVERY_COUNTRY_REQUIRED",
    "DELIVERY_FIRST_NAME_INVALID": "DELIVERY_FIRST_NAME_INVALID",
    "DELIVERY_FIRST_NAME_REQUIRED": "DELIVERY_FIRST_NAME_REQUIRED",
    "DELIVERY_FIRST_NAME_TOO_LONG": "DELIVERY_FIRST_NAME_TOO_LONG",
    "DELIVERY_INVALID_POSTAL_CODE_FOR_COUNTRY": "DELIVERY_INVALID_POSTAL_CODE_FOR_COUNTRY",
    "DELIVERY_INVALID_POSTAL_CODE_FOR_ZONE": "DELIVERY_INVALID_POSTAL_CODE_FOR_ZONE",
    "DELIVERY_LAST_NAME_INVALID": "DELIVERY_LAST_NAME_INVALID",
    "DELIVERY_LAST_NAME_REQUIRED": "DELIVERY_LAST_NAME_REQUIRED",
    "DELIVERY_LAST_NAME_TOO_LONG": "DELIVERY_LAST_NAME_TOO_LONG",
    "DELIVERY_NO_DELIVERY_AVAILABLE": "DELIVERY_NO_DELIVERY_AVAILABLE",
    "DELIVERY_NO_DELIVERY_AVAILABLE_FOR_MERCHANDISE_LINE": "DELIVERY_NO_DELIVERY_AVAILABLE_FOR_MERCHANDISE_LINE",
    "DELIVERY_OPTIONS_PHONE_NUMBER_INVALID": "DELIVERY_OPTIONS_PHONE_NUMBER_INVALID",
    "DELIVERY_OPTIONS_PHONE_NUMBER_REQUIRED": "DELIVERY_OPTIONS_PHONE_NUMBER_REQUIRED",
    "DELIVERY_PHONE_NUMBER_INVALID": "DELIVERY_PHONE_NUMBER_INVALID",
    "DELIVERY_PHONE_NUMBER_REQUIRED": "DELIVERY_PHONE_NUMBER_REQUIRED",
    "DELIVERY_POSTAL_CODE_INVALID": "DELIVERY_POSTAL_CODE_INVALID",
    "DELIVERY_POSTAL_CODE_REQUIRED": "DELIVERY_POSTAL_CODE_REQUIRED",
    "DELIVERY_ZONE_NOT_FOUND": "DELIVERY_ZONE_NOT_FOUND",
    "DELIVERY_ZONE_REQUIRED_FOR_COUNTRY": "DELIVERY_ZONE_REQUIRED_FOR_COUNTRY",
    "ERROR": "ERROR",
    "MERCHANDISE_LINE_LIMIT_REACHED": "MERCHANDISE_LINE_LIMIT_REACHED",
    "MERCHANDISE_NOT_APPLICABLE": "MERCHANDISE_NOT_APPLICABLE",
    "MERCHANDISE_NOT_ENOUGH_STOCK_AVAILABLE": "MERCHANDISE_NOT_ENOUGH_STOCK_AVAILABLE",
    "MERCHANDISE_OUT_OF_STOCK": "MERCHANDISE_OUT_OF_STOCK",
    "MERCHANDISE_PRODUCT_NOT_PUBLISHED": "MERCHANDISE_PRODUCT_NOT_PUBLISHED",
    "NO_DELIVERY_GROUP_SELECTED": "NO_DELIVERY_GROUP_SELECTED",
    "PAYMENTS_ADDRESS1_INVALID": "PAYMENTS_ADDRESS1_INVALID",
    "PAYMENTS_ADDRESS1_REQUIRED": "PAYMENTS_ADDRESS1_REQUIRED",
    "PAYMENTS_ADDRESS1_TOO_LONG": "PAYMENTS_ADDRESS1_TOO_LONG",
    "PAYMENTS_ADDRESS2_INVALID": "PAYMENTS_ADDRESS2_INVALID",
    "PAYMENTS_ADDRESS2_REQUIRED": "PAYMENTS_ADDRESS2_REQUIRED",
    "PAYMENTS_ADDRESS2_TOO_LONG": "PAYMENTS_ADDRESS2_TOO_LONG",
    "PAYMENTS_BILLING_ADDRESS_ZONE_NOT_FOUND": "PAYMENTS_BILLING_ADDRESS_ZONE_NOT_FOUND",
    "PAYMENTS_BILLING_ADDRESS_ZONE_REQUIRED_FOR_COUNTRY": "PAYMENTS_BILLING_ADDRESS_ZONE_REQUIRED_FOR_COUNTRY",
    "PAYMENTS_CITY_INVALID": "PAYMENTS_CITY_INVALID",
    "PAYMENTS_CITY_REQUIRED": "PAYMENTS_CITY_REQUIRED",
    "PAYMENTS_CITY_TOO_LONG": "PAYMENTS_CITY_TOO_LONG",
    "PAYMENTS_COMPANY_INVALID": "PAYMENTS_COMPANY_INVALID",
    "PAYMENTS_COMPANY_REQUIRED": "PAYMENTS_COMPANY_REQUIRED",
    "PAYMENTS_COMPANY_TOO_LONG": "PAYMENTS_COMPANY_TOO_LONG",
    "PAYMENTS_COUNTRY_REQUIRED": "PAYMENTS_COUNTRY_REQUIRED",
    "PAYMENTS_CREDIT_CARD_BASE_EXPIRED": "PAYMENTS_CREDIT_CARD_BASE_EXPIRED",
    "PAYMENTS_CREDIT_CARD_BASE_GATEWAY_NOT_SUPPORTED": "PAYMENTS_CREDIT_CARD_BASE_GATEWAY_NOT_SUPPORTED",
    "PAYMENTS_CREDIT_CARD_BASE_INVALID_START_DATE_OR_ISSUE_NUMBER_FOR_DEBIT": "PAYMENTS_CREDIT_CARD_BASE_INVALID_START_DATE_OR_ISSUE_NUMBER_FOR_DEBIT",
    "PAYMENTS_CREDIT_CARD_BRAND_NOT_SUPPORTED": "PAYMENTS_CREDIT_CARD_BRAND_NOT_SUPPORTED",
    "PAYMENTS_CREDIT_CARD_FIRST_NAME_BLANK": "PAYMENTS_CREDIT_CARD_FIRST_NAME_BLANK",
    "PAYMENTS_CREDIT_CARD_GENERIC": "PAYMENTS_CREDIT_CARD_GENERIC",
    "PAYMENTS_CREDIT_CARD_LAST_NAME_BLANK": "PAYMENTS_CREDIT_CARD_LAST_NAME_BLANK",
    "PAYMENTS_CREDIT_CARD_MONTH_INCLUSION": "PAYMENTS_CREDIT_CARD_MONTH_INCLUSION",
    "PAYMENTS_CREDIT_CARD_NAME_INVALID": "PAYMENTS_CREDIT_CARD_NAME_INVALID",
    "PAYMENTS_CREDIT_CARD_NUMBER_INVALID": "PAYMENTS_CREDIT_CARD_NUMBER_INVALID",
    "PAYMENTS_CREDIT_CARD_NUMBER_INVALID_FORMAT": "PAYMENTS_CREDIT_CARD_NUMBER_INVALID_FORMAT",
    "PAYMENTS_CREDIT_CARD_SESSION_ID": "PAYMENTS_CREDIT_CARD_SESSION_ID",
    "PAYMENTS_CREDIT_CARD_VERIFICATION_VALUE_BLANK": "PAYMENTS_CREDIT_CARD_VERIFICATION_VALUE_BLANK",
    "PAYMENTS_CREDIT_CARD_VERIFICATION_VALUE_INVALID_FOR_CARD_TYPE": "PAYMENTS_CREDIT_CARD_VERIFICATION_VALUE_INVALID_FOR_CARD_TYPE",
    "PAYMENTS_CREDIT_CARD_YEAR_EXPIRED": "PAYMENTS_CREDIT_CARD_YEAR_EXPIRED",
    "PAYMENTS_CREDIT_CARD_YEAR_INVALID_EXPIRY_YEAR": "PAYMENTS_CREDIT_CARD_YEAR_INVALID_EXPIRY_YEAR",
    "PAYMENTS_FIRST_NAME_INVALID": "PAYMENTS_FIRST_NAME_INVALID",
    "PAYMENTS_FIRST_NAME_REQUIRED": "PAYMENTS_FIRST_NAME_REQUIRED",
    "PAYMENTS_FIRST_NAME_TOO_LONG": "PAYMENTS_FIRST_NAME_TOO_LONG",
    "PAYMENTS_INVALID_POSTAL_CODE_FOR_COUNTRY": "PAYMENTS_INVALID_POSTAL_CODE_FOR_COUNTRY",
    "PAYMENTS_INVALID_POSTAL_CODE_FOR_ZONE": "PAYMENTS_INVALID_POSTAL_CODE_FOR_ZONE",
    "PAYMENTS_LAST_NAME_INVALID": "PAYMENTS_LAST_NAME_INVALID",
    "PAYMENTS_LAST_NAME_REQUIRED": "PAYMENTS_LAST_NAME_REQUIRED",
    "PAYMENTS_LAST_NAME_TOO_LONG": "PAYMENTS_LAST_NAME_TOO_LONG",
    "PAYMENTS_METHOD_REQUIRED": "PAYMENTS_METHOD_REQUIRED",
    "PAYMENTS_METHOD_UNAVAILABLE": "PAYMENTS_METHOD_UNAVAILABLE",
    "PAYMENTS_PHONE_NUMBER_INVALID": "PAYMENTS_PHONE_NUMBER_INVALID",
    "PAYMENTS_PHONE_NUMBER_REQUIRED": "PAYMENTS_PHONE_NUMBER_REQUIRED",
    "PAYMENTS_POSTAL_CODE_INVALID": "PAYMENTS_POSTAL_CODE_INVALID",
    "PAYMENTS_POSTAL_CODE_REQUIRED": "PAYMENTS_POSTAL_CODE_REQUIRED",
    "PAYMENTS_SHOPIFY_PAYMENTS_REQUIRED": "PAYMENTS_SHOPIFY_PAYMENTS_REQUIRED",
    "PAYMENTS_UNACCEPTABLE_PAYMENT_AMOUNT": "PAYMENTS_UNACCEPTABLE_PAYMENT_AMOUNT",
    "PAYMENTS_WALLET_CONTENT_MISSING": "PAYMENTS_WALLET_CONTENT_MISSING",
    "TAXES_DELIVERY_GROUP_ID_NOT_FOUND": "TAXES_DELIVERY_GROUP_ID_NOT_FOUND",
    "TAXES_LINE_ID_NOT_FOUND": "TAXES_LINE_ID_NOT_FOUND",
    "TAXES_MUST_BE_DEFINED": "TAXES_MUST_BE_DEFINED"
};

export const TransactionKind = {
    "AUTHORIZATION": "AUTHORIZATION",
    "CAPTURE": "CAPTURE",
    "CHANGE": "CHANGE",
    "EMV_AUTHORIZATION": "EMV_AUTHORIZATION",
    "SALE": "SALE"
};

export const TransactionStatus = {
    "ERROR": "ERROR",
    "FAILURE": "FAILURE",
    "PENDING": "PENDING",
    "SUCCESS": "SUCCESS"
};

export const UnitPriceMeasurementMeasuredType = {
    "AREA": "AREA",
    "LENGTH": "LENGTH",
    "VOLUME": "VOLUME",
    "WEIGHT": "WEIGHT"
};

export const UnitPriceMeasurementMeasuredUnit = {
    "CL": "CL",
    "CM": "CM",
    "G": "G",
    "KG": "KG",
    "L": "L",
    "M": "M",
    "M2": "M2",
    "M3": "M3",
    "MG": "MG",
    "ML": "ML",
    "MM": "MM"
};

export const UnitSystem = {
    "IMPERIAL_SYSTEM": "IMPERIAL_SYSTEM",
    "METRIC_SYSTEM": "METRIC_SYSTEM"
};

export const WeightUnit = {
    "GRAMS": "GRAMS",
    "KILOGRAMS": "KILOGRAMS",
    "OUNCES": "OUNCES",
    "POUNDS": "POUNDS"
};