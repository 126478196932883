import {
	LanguageCode,
	type CountryCode$options,
	type LanguageCode$options,
	CountryCode
} from '$houdini';
import { languageStore } from '$lib/store/language.store';
import { get } from 'svelte/store';
import { LOCALE_COUNTRY_PRERENDER_MAPPING } from '$lib/i18n/constants';

const fallbackLocale = LanguageCode.DE;
const fallbackCountry = CountryCode.DE;

export const ensureLocale = (locale: string) => {
	return locale.toUpperCase() as LanguageCode$options;
};

export const ensureCountry = (country: string) => {
	return country.toUpperCase() as CountryCode$options;
};

export const getCountry = () => {
	const country = get(languageStore)?.country;
	if (!country) return fallbackCountry;

	return ensureCountry(country);
};
export const getLanguage = () => {
	const locale = get(languageStore)?.locale;
	if (!locale) return fallbackLocale;

	return ensureLocale(locale);
};

export function languageCodeToCountryCode(language: string): string {
	switch (language.toLowerCase()) {
		case 'de':
			return 'de';
		case 'en':
			return 'gb';
		default:
			return language.toLowerCase();
	}
}

export const supplyPathParamsWithLocalization = <E>(paramData: (Record<string, string> & E)[]) => {
	return LOCALE_COUNTRY_PRERENDER_MAPPING.map(({ locale, country }) => {
		return paramData.map((param) => ({
			...param,
			locale,
			country
		}));
	}).flat();
};

export function currencyCodeToSymbol(currency: string) {
	return new Intl.NumberFormat('en-UK', { style: 'currency', currency })
		.format(0)
		.replace(/(\d|\.|\,)*/g, '');
}
