export default {
    "name": "CustomerActivate",
    "kind": "HoudiniMutation",
    "hash": "c00fd6e27d730643b6cb8c1efc3148687c9193fb49b1c3d2aeaa28ca1f659b2e",

    "raw": `mutation CustomerActivate($token: URL!, $password: String!, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  customerActivateByUrl(activationUrl: $token, password: $password) {
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
`,

    "rootType": "Mutation",

    "selection": {
        "fields": {
            "customerActivateByUrl": {
                "type": "CustomerActivateByUrlPayload",
                "keyRaw": "customerActivateByUrl(activationUrl: $token, password: $password)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "customerAccessToken": {
                            "type": "CustomerAccessToken",
                            "keyRaw": "customerAccessToken",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "accessToken": {
                                        "type": "String",
                                        "keyRaw": "accessToken",
                                        "visible": true
                                    },

                                    "expiresAt": {
                                        "type": "DateTime",
                                        "keyRaw": "expiresAt",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "customerUserErrors": {
                            "type": "CustomerUserError",
                            "keyRaw": "customerUserErrors",

                            "selection": {
                                "fields": {
                                    "code": {
                                        "type": "CustomerErrorCode",
                                        "keyRaw": "code",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "field": {
                                        "type": "String",
                                        "keyRaw": "field",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "message": {
                                        "type": "String",
                                        "keyRaw": "message",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "token": "URL",
            "password": "String",
            "country": "CountryCode",
            "language": "LanguageCode"
        },

        "types": {}
    }
};

"HoudiniHash=8ff0b204c2ba04a4eef2b7bd877ccfda5267af851826c704f0e256856c46e948";