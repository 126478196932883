export default {
    "name": "CustomerResetPassword",
    "kind": "HoudiniMutation",
    "hash": "2352cddc89376f276363a6f96564fe880cce43aed7f9361e7799d34df128bad0",

    "raw": `mutation CustomerResetPassword($email: String!, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  customerRecover(email: $email) {
    customerUserErrors {
      code
      field
      message
    }
  }
}
`,

    "rootType": "Mutation",

    "selection": {
        "fields": {
            "customerRecover": {
                "type": "CustomerRecoverPayload",
                "keyRaw": "customerRecover(email: $email)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "customerUserErrors": {
                            "type": "CustomerUserError",
                            "keyRaw": "customerUserErrors",

                            "selection": {
                                "fields": {
                                    "code": {
                                        "type": "CustomerErrorCode",
                                        "keyRaw": "code",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "field": {
                                        "type": "String",
                                        "keyRaw": "field",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "message": {
                                        "type": "String",
                                        "keyRaw": "message",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "email": "String",
            "country": "CountryCode",
            "language": "LanguageCode"
        },

        "types": {}
    }
};

"HoudiniHash=15f5e1d2182905c92d63096e367a2ee47492db620acdad8ae04b30c69c25e642";