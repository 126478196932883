export default {
    "name": "CustomerAccessTokenDelete",
    "kind": "HoudiniMutation",
    "hash": "476e6a132c81a08e27d06fb415da134a76e6cee130bd33b577ebce20cf19d970",

    "raw": `mutation CustomerAccessTokenDelete($customerAccessTokenDeleteInput: String!) {
  customerAccessTokenDelete(customerAccessToken: $customerAccessTokenDeleteInput) {
    userErrors {
      field
      message
    }
  }
}
`,

    "rootType": "Mutation",

    "selection": {
        "fields": {
            "customerAccessTokenDelete": {
                "type": "CustomerAccessTokenDeletePayload",
                "keyRaw": "customerAccessTokenDelete(customerAccessToken: $customerAccessTokenDeleteInput)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "userErrors": {
                            "type": "UserError",
                            "keyRaw": "userErrors",

                            "selection": {
                                "fields": {
                                    "field": {
                                        "type": "String",
                                        "keyRaw": "field",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "message": {
                                        "type": "String",
                                        "keyRaw": "message",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "customerAccessTokenDeleteInput": "String"
        },

        "types": {}
    }
};

"HoudiniHash=36e5e4586140137a9ed36b544ef228d311644074531c88d03cb54edef6a034be";