export default {
    "name": "CustomerAccessTokenCreate",
    "kind": "HoudiniMutation",
    "hash": "4d05cfc4958d662df120d1d66ad670f1456f76ce2b8a2c68b8384d2a54ca7c45",

    "raw": `mutation CustomerAccessTokenCreate($input: CustomerAccessTokenCreateInput!, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  customerAccessTokenCreate(input: $input) {
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
`,

    "rootType": "Mutation",

    "selection": {
        "fields": {
            "customerAccessTokenCreate": {
                "type": "CustomerAccessTokenCreatePayload",
                "keyRaw": "customerAccessTokenCreate(input: $input)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "customerAccessToken": {
                            "type": "CustomerAccessToken",
                            "keyRaw": "customerAccessToken",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "accessToken": {
                                        "type": "String",
                                        "keyRaw": "accessToken",
                                        "visible": true
                                    },

                                    "expiresAt": {
                                        "type": "DateTime",
                                        "keyRaw": "expiresAt",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "customerUserErrors": {
                            "type": "CustomerUserError",
                            "keyRaw": "customerUserErrors",

                            "selection": {
                                "fields": {
                                    "code": {
                                        "type": "CustomerErrorCode",
                                        "keyRaw": "code",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "field": {
                                        "type": "String",
                                        "keyRaw": "field",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "message": {
                                        "type": "String",
                                        "keyRaw": "message",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "input": "CustomerAccessTokenCreateInput",
            "country": "CountryCode",
            "language": "LanguageCode"
        },

        "types": {
            "CustomerAccessTokenCreateInput": {
                "email": "String",
                "password": "String"
            }
        }
    }
};

"HoudiniHash=f7e9c364c9e2eb9fee58ed15976a5806c1735dc095435c1de72fcef2aabc45f1";