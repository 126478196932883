import { PUBLIC_SHOPIFY_LOCALES, PUBLIC_SUPPORTED_LANGUAGES } from '$env/static/public';

export const SUPPORTED_LANGUAGES = PUBLIC_SUPPORTED_LANGUAGES.split(',');
export const SUPPORTED_COUNTRIES = PUBLIC_SHOPIFY_LOCALES.split(',');
export type SupportedLanguages = (typeof SUPPORTED_LANGUAGES)[number];
export type SupportedCountries = (typeof SUPPORTED_COUNTRIES)[number];

/**
 * This generates a list of all possible locales and countries for generating prerendered paths
 */
export const LOCALE_COUNTRY_PRERENDER_MAPPING = SUPPORTED_LANGUAGES.map((l) => {
	return SUPPORTED_COUNTRIES.map((c) => {
		return {
			locale: l,
			country: c
		};
	});
}).flat();
